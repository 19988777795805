<template>
  <section id="contact" class="contact-general-information mt-3" :class="{'mobile':isMobile}">
    <div class="product-rate container">
      <h2 class="general-title text-center">
        {{ $t('product_page.contacts') }}
      </h2>
      <div class="container d-grid contact-information">

        <div class="d-flex flex-column" :class="`${isMobile?'justify-content-between':'justify-content-center'}`">
          <div>

            <ul class="contact-details list-unstyled mb-0">

              <li class=" contact-details__item d-flex" :class="{'contact-details-mobile':isMobile}">
              <span
                class="text-capitalize font-weight-bold contact-details__item__name d-flex contact-details-title"
              >
                {{ $t('contact.seller_name') }}:
              </span>
                <span
                  class=" ml-1 text-decoration-none contact-details__item__value"
                > {{ seller.firstname || 'None' }}</span>
              </li>
              <li class="contact-details__item d-flex align-items-center" :class="{'contact-details-mobile':isMobile}">
              <span
                class="font-weight-bold text-capitalize contact-details__item__name d-flex contact-details-title"
              >
                {{ $t('contact.phone_number') }}:
              </span>

                <ul
                  v-if="seller.phone"
                  class="list-unstyled d-flex align-items-center"
                >
                  <li class="phone__item">
                    <a
                      :href="`viber://chat?number=%2B${seller.phone}`"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src="~/assets/images/wi.svg" alt="viber icon" width="22" height="22" data-not-lazy />
                    </a>
                  </li>
                  <li class="phone__item">
                    <a
                      :href="`https://api.whatsapp.com/send?phone=${seller.phone}`"
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src="~/assets/images/wh.svg" alt="whatsapp icon" width="22" height="22" data-not-lazy />
                    </a>
                  </li>
                  <app-phone-link :tel="seller.phone" classes="text-capitalize text-decoration-none contact-details__item__value d-flex align-items-end font-size-14">
                    {{ renderSellerPhone(seller.phone) || 'None' }}
                  </app-phone-link>
                </ul>
              </li>

            </ul>
          </div>

        </div>
        <div class="d-flex flex-column" :class="`${isMobile?'justify-content-between':'justify-content-center'}`">
          <div>
            <ul class="contact-details list-unstyled mb-0">
              <li class="contact-details__item d-flex" :class="{'contact-details-mobile':isMobile}">
            <span
              class=" font-weight-bold text-capitalize contact-details__item__name d-flex contact-details-title"
            >
              {{ $t('contact.mail') }}:
            </span>
                <a
                  :href="`mainTo:${seller.email}`"
                  class="ml-1 text-decoration-none contact-details__item__value"
                >
                  {{ seller.email || 'None' }}
                </a>
              </li>
              <li class="contact-details__item d-flex align-items-center" :class="{'contact-details-mobile':isMobile}">
              <span
                class=" font-weight-bold text-capitalize contact-details__item__name d-flex contact-details-title white-space-nowrap"
              >
                {{ $t('contact.seller_address') }}:
              </span>
                <ul
                  class="list-unstyled d-flex align-items-center"
                >
                  <li class="ml-1">
                    <a
                      :href="`https://www.google.com/maps/search/?api=1&query=${address.coordinates.lat},${address.coordinates.lng}`"
                      rel="noreferrer"
                      target="_blank"
                      class="map"
                      @click.stop
                    >
                      <img src="~/assets/images/Vector.svg" alt="viber icon" width="15" height="15" data-not-lazy />
                    </a>
                  </li>
                  <span
                    class=" ml-1 text-decoration-none contact-details__item__value"
                  > {{ address.text || 'None' }}</span>

                </ul>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row mt-4 mb-4" :class="`${isMobile?'justify-content-between':'justify-content-center'}`">
        <nuxt-link
          v-if="!selletPage"
          :to="{ path: localePath(`/seller/${seller.id}`) }"
          tag="a"
          class="btn btn-outline max-w-fit shadow-none rounded-0 d-inline-flex align-items-center justify-content-center text-uppercase w-100"
        >
          {{ $t('product_page.go_to_the_company_page') }}
        </nuxt-link>
      </div>
    </div>
  </section>
</template>

<script>
import { renderSellerPhone } from '~/core/utils'

export default {
  name: 'CardContact',

  props: {
    seller: {
      required: true,
      type: Object
    },
    selletPage: {
      required: false,
      type: Boolean
    }
  },
  data () {
    return {
      address: {
        text: '',
        coordinates: { lat: 40.181837, lng: 44.513606 }
      }
    }
  },
  computed: {
    getFullAddress () {
      return `${this.seller.address.country}, ${this.seller.address.city}, ${this.seller.address.street_address}, ${this.seller.address.zip_code}, ${this.seller.address.street_address_optional}`.toUpperCase()
    }
  },
  mounted () {
    try {
      const data = JSON.parse(this.seller.address)
      const [lng, lat] = data?.geometry?.coordinates
      const addressData = {
        text: data?.properties?.GeocoderMetaData?.text,
        coordinates: { lat, lng }
      }
      this.address = addressData
    } catch {
      const addressData = {
        text: this.seller.address,
        coordinates: { lat: 40.181837, lng: 44.513606 }
      }
      this.address = addressData
    }
  },
  methods: {
    renderSellerPhone
  }
}
</script>

<style scoped>
.map {
  display: flex;
  z-index: 0;
}

.contact-details__item {
  margin-bottom: 0;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 100% */
  letter-spacing: 1px;
}
.contact-general-information:not(.mobile) .general-title{
  color: #000;
  font-family: Mardoto;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 27px;
}
.contact-general-information:not(.mobile) .btn-outline{
  margin: 10px 10px 0 10px;
  width: calc(100% - 20px) !important;
}
.contact-details__item:not(.contact-details-mobile) {
  margin-bottom: 20px;
}
.contact-details__item:not(.contact-details-mobile) .contact-details-title {
  font-size: 14px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-bottom: 0;
}
.font-weight-bold {
  font-weight:600!important
}
.ml-1 {
  margin-left:5px
}
.contact-details__item:not(.contact-details-mobile) div {
  max-width: 50%;
  text-align: right;
}
.contact-details-mobile {
  flex-direction: column;
  padding-bottom: 6px;
  margin-bottom: 6px;
  border-bottom: 1px solid #e0e3e7;
}
.contact-details__item.contact-details-mobile {
  align-items: flex-start !important;
}
.contact-details__item__name:after {
  display: none;
}
.contact-details-title {
  margin-bottom: 10px;
  color: #1d3865;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.font-size-14 {
  font-size: 14px;
  padding-left: 5px;
}

.phone__item a {
  padding: 0 5px;
}

@media all and (max-width: 1199.9px) {
  .contact-information .btn-outline {
    margin: 20px 0;
  }
  .general-title {
    color: var(--font-1, #000);
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 30.8px */
  }
}

@media all and (max-width: 766.9px) {
  .map >>> .vue-map-container {
    pointer-events: none;
  }
}
</style>
